import { useState } from 'react';
import { Navigate, Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import HomeP from '../pages/HomeP';
import GalleryPage from '../pages/GalleryPage';
import Terms from '../components/Terms';
import Privacy from '../components/Privacy';
import DeleteAccount from '../components/DeleteAccount';

export const AppRouter = () => {
  const [isArabic, setIsArabic] = useState(false);
  const handleLanguageChange = () => {
    setIsArabic((prevIsArabic) => !prevIsArabic);
  };

  const sectionTranslations: Record<string, string> = {
    coffeeshops: isArabic ? 'أصحاب-المقاهي' : 'coffeeshops',
    customers: isArabic ? 'محبي-القهوة' : 'customers',
    gallery: isArabic ? 'المعرض' : 'gallery',
    partner: isArabic ? 'انضم-كشريك' : 'become-a-partner',
    terms: isArabic ? 'terms-and-conditions' : 'terms-and-conditions',
    privacy: isArabic ? 'privacy-policy' : 'privacy-policy',
    deleteAccount: isArabic ? 'delete-account' : 'delete-account',
    home: isArabic ? 'home' : 'home',
  };

  return (
    <Router>
      <Routes>
        <Route
          path={`/en`}
          element={<HomeP isArabic={isArabic} handleLanguageChange={handleLanguageChange}
            sectionTranslations={sectionTranslations}
            currentSection={sectionTranslations.home}
          />}
        />
        <Route
          path={`/ar`}
          element={<HomeP isArabic={isArabic} handleLanguageChange={handleLanguageChange}
            sectionTranslations={sectionTranslations}
            currentSection={sectionTranslations.home}
          />}
        />
        <Route
          path={`/en/terms`}
          element={
            <Terms
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              sectionTranslations={sectionTranslations}
              currentSection={sectionTranslations.terms}

            />
          }
        />
        <Route
          path={`/ar/terms`}
          element={
            <Terms
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              sectionTranslations={sectionTranslations}
              currentSection={sectionTranslations.terms}

            />
          }
        />
        <Route
          path={`/en/privacy`}
          element={
            <Privacy
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              sectionTranslations={sectionTranslations}
              currentSection={sectionTranslations.privacy}

            />
          }
        />
        <Route
          path={`/ar/privacy`}
          element={
            <Privacy
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              sectionTranslations={sectionTranslations}
              currentSection={sectionTranslations.privacy}

            />
          }
        />

        <Route
          path={`/en/delete-account`}
          element={
            <DeleteAccount/>
          }
        />
        <Route
          path={`/ar/delete-account`}
          element={
            <DeleteAccount/>
          }
        />

        <Route
          path="/en/gallery"
          element={
            <GalleryPage
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              sectionTranslations={sectionTranslations}
              currentSection={sectionTranslations.gallery}
            />
          }
        />
        <Route
          path="/ar/gallery"
          element={
            <GalleryPage
              isArabic={isArabic}
              handleLanguageChange={handleLanguageChange}
              sectionTranslations={sectionTranslations}
              currentSection={sectionTranslations.gallery}
            />
          }
        />

        <Route path="*" element={<Navigate to={isArabic? '/ar' : '/en'} />} />
      </Routes>
    </Router>
  );
}

